import Fallback from '../components/Fallback';
import Panel from '../components/panels/Panel';
import Box from '../components/Box';

const Error = ({ status }) => {
    const title = {
        503: '503: Service niet beschikbaar',
        500: '500: Serverfout',
        404: '404: Pagina niet gevonden',
        403: '403: Verboden',
    }[status];

    const description = {
        503: 'We zijn bezig met onderhoud.',
        500: 'Er is iets misgegaan op onze servers.',
        404: 'De pagina die u zoekt is niet gevonden.',
        403: 'U heeft geen toegang tot deze pagina.',
    }[status];

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100vh'} width={'100%'}>
            <Panel height={'auto'}>
                <Fallback title={title} description={description} image={'/assets/images/illustrations/empty-workflows.svg'} />
            </Panel>
        </Box>
    );
};

Error.Layout = (children) => <AuthenticationLayout children={children} />;

export default Error;
